<template>
  <ValidationObserver @submit="formCheck">
    <UiModalContainer
      footer-classes="flex justify-end"
      :modal-show="show"
      :modal-width="40"
      @handleClick="handleClick"
    >
      <template v-slot:header>
        <span v-i18n="attendanceTranslation">{{ headerTitle }}</span>
      </template>
      <template v-slot>
        <div class="px-5 my-5">
          <div class="flex flex-col lg:flex-row gap-3 justify-between items-start">
            <div class="flex-1 w-full mb-5">
              <UiDateTimePicker
                v-model="checked.checked_at"
                title="Time"
                type="time"
                :current-date="true"
                rules="required"
              ></UiDateTimePicker>
            </div>
          </div>
          <div class="flex flex-col lg:flex-row gap-3 justify-between">
            <UiSingleSelect
              v-model="checked.teacher"
              title="Teacher"
              label="full_name"
              :image="true"
              :options="teacherList || []"
              reduce="id"
              class="flex-1 w-full lg:w-1/2"
              rules="required"
            />

            <UiInput
              v-model="checked.comment"
              placeholder="Comment"
              title="Comment"
              class="flex-1 w-full lg:w-1/2"
              type="text"
            ></UiInput>
          </div>
        </div>
      </template>
      <template v-slot:footer>
        <div class="flex gap-3 rtl:gap-x-4">
          <UIButton :disabled="isLoading" @click="handleClick('close')">Cancel</UIButton>
          <UIButton button="primary">
            <span v-if="isLoading"><Loader /></span>
            <span v-else>Mark</span>
          </UIButton>
        </div>
      </template>
    </UiModalContainer>
  </ValidationObserver>
</template>

<script>
import { Form as ValidationObserver } from 'vee-validate'
import UiModalContainer from '@components/UiElements/UiModalContainer.vue'
import UiDateTimePicker from '@src/components/UiElements/UIDateTimePicker.vue'
import UiInput from '@components/UiElements/UiInputBox.vue'
import Loader from '@components/BaseComponent/Loader.vue'
import { utcOffsetZero } from '@src/utils/format-date.js'
import UiSingleSelect from '@/src/components/UiElements/UiSingleSelect.vue'
import UIButton from '@src/components/UiElements/UIButton.vue'
import { mapActions, mapState } from 'vuex'

export default {
  name: 'StudentCheckInModal',
  components: {
    UiModalContainer,
    UiSingleSelect,
    Loader,
    UiDateTimePicker,
    ValidationObserver,
    UiInput,
    UIButton,
  },
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    type: {
      type: String,
      default: 'checkIn',
    },
    teacherList: {
      type: Array,
      default: () => [],
    },
  },

  emits: ['refetch', 'close'],
  data() {
    return {
      attendanceTranslation: 'attendanceTranslation', // i18n
      isLoading: false,
      checked: {
        checked_at: '',
        teacher: '',
        comment: '',
      },
    }
  },
  computed: {
    headerTitle() {
      return type === 'checkIn' ? 'SCIOM_HEADER_IF' : 'SCIOM_HEADER_ELSE'
    },
    ...mapState({
      classId: (state) => state.layout.currentSectionScope?.id,
    }),
  },

  methods: {
    utcOffsetZero,
    handleClick(status) {
      switch (status) {
        case 'close':
          this.$emit('close')
          break
      }
    },

    clearForm() {
      this.checked.checked = ''
      this.checked.teacher = null
      this.checked.comment = ''
    },
    async formCheck() {
      if (!this.isLoading) {
        try {
          const data = {
            checked_at: this.checked.checked_at,
            is_bulk: true,
            users: [
              {
                user_id: this.checked.teacher,
                comment: this.checked.comment || '',
              },
            ],
            checked_type: this.type === 'checkIn' ? 'checked_in' : 'checked_out',
          }

          this.isLoading = true

          await this.markTeacherCheckInOut(data)

          this.clearForm()

          this.isLoading = false

          this.$emit('refetch')
          this.$emit('close')
        } catch (err) {
          this.isLoading = false
        }
      }
    },
    ...mapActions('teacher', ['getTeacherList', 'markTeacherCheckInOut']),
  },
}
</script>
