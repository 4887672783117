<template>
  <div>
    <TitleWrapper
      :filter-option="true"
      title="Teacher Attendance"
      tooltip-title="TEACHER_ATTEND"
      :display-breadcrumb="true"
    >
      <template v-slot:filterItems>
        <div
          v-i18n="attendanceTranslation"
          class="font-roboto font-normal text-text-color text-base sm:text-xl mb-4"
        >
          Search Attendance
        </div>
        <div class="grid grid-cols-1 md:grid-cols-2 gap-5">
          <div class="filter-block pb-6 flex flex-wrap justify-between gap-10 border-primary-grey">
            <UiMultiSelectBox
              v-model="filterId"
              input-color="bg-white"
              :options="teacherId"
              :reduce="'id'"
              title="TCHR_ID"
              :label="'tchrId'"
              class="flex-1"
            />
          </div>
          <div class="filter-block pb-6 flex flex-wrap justify-between gap-5 border-primary-grey">
            <UiMultiSelectBox
              v-model="filterName"
              input-color="bg-white"
              :options="teacherName"
              :reduce="'id'"
              title="TCHR_NAME"
              :label="'name'"
              class="flex-1"
            />
          </div>
        </div>
        <div class="grid grid-cols-1 md:grid-cols-2 gap-5">
          <div class="text-text-color text-xs font-normal font-roboto flex pb-1 capitalize">
            <span v-i18n="title">FILTER_IN</span>
          </div>
          <div class="text-text-color text-xs font-normal font-roboto flex pb-1 capitalize">
            <span v-i18n="title">FILTER_OUT</span>
          </div>
        </div>
        <div class="grid grid-cols-1 pb-6 md:grid-cols-2 gap-5">
          <div class="flex flex-col">
            <div class="border rounded-lg flex flex-wrap justify-between gap-5 border-primary-grey">
              <UITimePicker
                v-model="attendanceIn"
                class="flex-1"
                :hide-title="true"
                :is-date-time="true"
                input-color="bg-white"
              />
            </div>
            <div class="flex flex-row">
              <div class="flex flex-row">
                <span>
                  <UiCheckbox :model-value="false" />
                </span>
                <span v-i18n="title" class="pr-3 self-center">FILTER_BFR</span>
              </div>
              <div class="flex flex-row">
                <span>
                  <UiCheckbox :model-value="false" />
                </span>
                <span v-i18n="title" class="self-center">FILTER_AFTR</span>
              </div>
            </div>
          </div>
          <div class="flex flex-col">
            <div
              class="filter-block rounded-lg flex border flex-wrap justify-between gap-5 border-primary-grey"
            >
              <UITimePicker
                v-model="attendanceOut"
                class="flex-1"
                :hide-title="true"
                :is-date-time="true"
                input-color="bg-white"
              />
            </div>
            <div class="flex flex-row">
              <div class="flex flex-row">
                <span>
                  <UiCheckbox :model-value="false" />
                </span>
                <span v-i18n="title" class="pr-3 self-center">FILTER_BFR</span>
              </div>
              <div class="flex flex-row">
                <span>
                  <UiCheckbox :model-value="false" />
                </span>
                <span v-i18n="title" class="self-center">FILTER_AFTR</span>
              </div>
            </div>
          </div>
        </div>
      </template>
    </TitleWrapper>
  </div>
</template>

<script>
import TitleWrapper from '@components/TitleWrapper.vue'
import UiMultiSelectBox from '@src/components/UiElements/UiMultiSelect.vue'
import UITimePicker from '@/src/components/UiElements/UiTimePicker.vue'
import UiCheckbox from '@components/UiElements/UiCheckbox.vue'
export default {
  name: 'TeacherAttendanceFilter',
  components: {
    TitleWrapper,
    UITimePicker,
    UiMultiSelectBox,
    UiCheckbox,
  },
  data() {
    return {
      attendanceTranslation: 'attendanceTranslation',
      title: 'title',
      filterId: null,
      teacherId: [
        { id: 0, tchrId: '1' },
        { id: 1, tchrId: '2' },
        { id: 2, tchrId: '3' },
        { id: 3, tchrId: '4' },
        { id: 4, tchrId: '5' },
      ],
      filterName: null,
      attendanceIn: null,
      attendanceOut: null,
      teacherName: [
        { id: 0, name: 'Ahmad' },
        { id: 1, name: 'Sana' },
        { id: 2, name: 'Ayesha' },
        { id: 3, name: 'Iqra' },
        { id: 4, name: 'Mudashir' },
      ],
    }
  },
}
</script>
