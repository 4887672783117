<template>
  <div>
    <StaffCheckInOutFilter />
    <section
      class="main-content bg-primary-white rounded-md mt-5 p-1 md:mx-0 mx-5"
      :class="
        !isLoading && isEmpty(attendance) && isEmpty(events)
          ? 'border-0'
          : 'border-border-color-lighter border'
      "
    >
      <CalendarActionsWrapper
        :calendar-date="filterView === 'Week' ? dateTimeRange : currentDate"
        :filter-view="filterView"
        :filters="['Month', 'Week', 'Day']"
        @selectDate="setCalendarDate"
        @selectDateRange="setCalendarDateRange"
        @setFilter="setFilter"
        @applyFilter="applyDateFilter"
      ></CalendarActionsWrapper>
      <template v-if="isLoading"><Loader class="mt-20 mb-40" :content="true" /></template>
      <div v-else>
        <UiCalendar
          v-if="filterView === 'Month'"
          :key="forceRenderCalendar"
          :calendar-date="currentDate"
        >
          <!-- change the way of passing Dynamic Slot Names -->
          <template v-for="(event, index) in attendance" v-slot:[`day-${getDay(event.date)}`]>
            <div v-if="event.hasOwnProperty.call(event, 'isAction')" :key="index">
              <div
                v-if="!event.isAction"
                class="time-schedule flex justify-center items-center gap-1 text-xs status mb-1 cursor-pointer"
                @click="singleRecord(event)"
              >
                <p
                  class="dot dot_size h-2 w-2 rounded-full"
                  :style="{ backgroundColor: event.checkedStatus.color }"
                ></p>
                <span
                  class="text-white text-xs inline-block rounded-sm whitespace-no-wrap text_size presentDate"
                  :style="{
                    backgroundColor: event.checkedStatus.presentColor,
                  }"
                >
                  {{ `${event.checked_time}` }}
                </span>
              </div>
              <div v-if="event.isAction" class="time-schedule viewMore">
                <p
                  class="font-roboto font-medium text-primary-grey-light viewMoreText cursor-pointer"
                  @click="clickDay(event.date)"
                >
                  {{ $t(`schedule.View More`) + ' ...' }}
                </p>
              </div>
            </div>
          </template>
        </UiCalendar>
        <WeeklyView
          v-else-if="filterView === 'Week'"
          :theads="dayRange"
          :table-time="[timeRange[0] || INITIAL_START_TIME, timeRange[1] || INITIAL_END_TIME]"
          :events="events"
          :hide-overlap="true"
          :hide-actions="true"
          @viewEvent="viewEvent"
        ></WeeklyView>
        <DayListViewCheckInOut
          v-else-if="filterView === 'Day'"
          :list="attendance"
          :current-date="currentDate"
          user-name="Teacher Name"
        >
          <template v-slot:notFound>
            <NoRecordFound />
          </template>
        </DayListViewCheckInOut>
      </div>
    </section>
    <UiModalContainer
      footer-classes="flex justify-end"
      :modal-show="dayModal"
      :modal-width="40"
      @handleClick="handleClick"
    >
      <template v-slot:header>
        {{ $t('attendanceTranslation.SAM_HT') }}
      </template>
      <template v-slot>
        <div v-for="(sch, idx) in detailsEventsArr" :key="sch.id" class="px-3 md:px-5 mb-8">
          <div class="flex flex-col gap-5 sm:flex-row mt-6">
            <span class="text-2xl text-label-text font-bold">
              {{
                sch.checked_type === 'checked_in'
                  ? $t('rightBar.RB_HEADER_BUTTON1_TITLE')
                  : $t('rightBar.RB_HEADER_BUTTON2_TITLE')
              }}
            </span>
            <span class="subjectColor"></span>
          </div>
          <div class="flex flex-1 justify-between">
            <div class="py-4 flex flex-1 items-center">
              <icon icon="calender" color="primary-green" height="21" width="18" />
              <span class="px-2 md:px-3 text-xs md:text-base text-primary-grey-light">
                {{ sch.date || sch.day }}
              </span>
            </div>
            <div class="py-4 flex flex-1 clock-icon items-center">
              <icon icon="clock" color="primary-green" height="21" width="18" />
              <span class="px-2 md:px-3 text-xs md:text-base text-primary-grey-light">
                {{ sch.time }}
              </span>
            </div>
          </div>
          <div class="flex justify-between">
            <div class="py-3 flex flex-1">
              <div class="text-xs md:text-base text-label-text font-bold rtl:text-right">
                <span v-i18n="attendanceTranslation">Marked By</span>
                :

                <span class="md:px-3 text-primary-grey-light font-normal">
                  {{ sch.first_name }} {{ sch.last_name }}
                </span>
              </div>
            </div>
            <div class="py-3 flex flex-1">
              <div class="text-xs md:text-base text-label-text font-bold rtl:text-right">
                <span v-i18n="attendanceTranslation">Total Count</span>
                :

                <span class="md:px-3 text-primary-grey-light font-normal">
                  {{ sch.checked_count }}
                </span>
              </div>
            </div>
          </div>
          <div class="flex justify-between">
            <div class="py-3 flex flex-1">
              <div class="text-xs md:text-base text-label-text font-bold rtl:text-right">
                <span v-i18n="attendanceTranslation">First Name</span>
                :

                <span class="md:px-3 text-primary-grey-light font-normal">
                  {{ sch.first_name }}
                </span>
              </div>
            </div>
            <div class="py-3 flex flex-1">
              <div class="text-xs md:text-base text-label-text font-bold rtl:text-right">
                <span v-i18n="attendanceTranslation">Last Name</span>
                :

                <span class="md:px-3 text-primary-grey-light font-normal">
                  {{ sch.last_name }}
                </span>
              </div>
            </div>
          </div>
          <hr v-if="detailsEventsArr.length > 1 && idx !== detailsEventsArr.length - 1" />
        </div>
      </template>
    </UiModalContainer>
    <CheckInOutModal
      :type="checkInModal ? 'checkIn' : 'checkOut'"
      :show="checkInModal || checkOutModal"
      :teacher-list="teacherList"
      @close="closeStudentCheckModal"
      @refetch="applyDateFilter"
    />
    <WeeklytDetailedModal
      footer-classes="flex justify-end"
      :modal-show="routinesDetailModal"
      :modal-width="40"
      @handleClick="handleRoutinesDetailClick"
    >
      <template v-slot:header>
        {{ $t('attendanceTranslation.SAM_HT') }}
      </template>
      <template v-slot>
        <div v-for="sch in detailsEventsArr" :key="sch.id" class="px-3 md:px-5 mb-8">
          <div class="flex flex-col gap-5 sm:flex-row mt-6">
            <span class="text-2xl text-label-text font-bold">
              {{
                sch.title === 'checked_in'
                  ? $t('rightBar.RB_HEADER_BUTTON1_TITLE')
                  : $t('rightBar.RB_SECTION_BTN')
              }}
            </span>
          </div>
          <div class="flex flex-1 justify-between">
            <div class="py-4 flex flex-1 items-center">
              <icon icon="calender" color="primary-green" height="21" width="18" />
              <span class="px-2 md:px-3 text-xs md:text-base text-primary-grey-light">
                {{ sch.date || sch.day }}
              </span>
            </div>
            <div class="py-4 flex flex-1 clock-icon items-center">
              <icon icon="clock" color="primary-green" height="21" width="18" />
              <span class="px-2 md:px-3 text-xs md:text-base text-primary-grey-light">
                {{ sch.startTime }}
              </span>
            </div>
          </div>
          <div class="flex justify-between">
            <div class="py-3 flex flex-1">
              <div class="text-xs md:text-base text-label-text font-bold rtl:text-right">
                <span v-i18n="attendanceTranslation">Marked By</span>
                :

                <span class="md:px-3 text-primary-grey-light font-normal">
                  {{ sch.checked_by_fname }} {{ sch.checked_by_lname }}
                </span>
              </div>
            </div>
            <div class="py-3 flex flex-1">
              <div class="text-xs md:text-base text-label-text font-bold rtl:text-right">
                <span v-i18n="attendanceTranslation">Total Count</span>
                :

                <span class="md:px-3 text-primary-grey-light font-normal">
                  {{ sch.check_count }}
                </span>
              </div>
            </div>
          </div>
          <div class="flex justify-between">
            <div class="py-3 flex flex-1">
              <div class="text-xs md:text-base text-label-text font-bold rtl:text-right">
                <span v-i18n="attendanceTranslation">First Name</span>
                :

                <span class="md:px-3 text-primary-grey-light font-normal">
                  {{ sch.checked_by_fname }}
                </span>
              </div>
            </div>
            <div class="py-3 flex flex-1">
              <div class="text-xs md:text-base text-label-text font-bold rtl:text-right">
                <span v-i18n="attendanceTranslation">Last Name</span>
                :

                <span class="md:px-3 text-primary-grey-light font-normal">
                  {{ sch.checked_by_lname }}
                </span>
              </div>
            </div>
          </div>
          <hr />
        </div>
      </template>
    </WeeklytDetailedModal>
  </div>
</template>

<script>
import { isEmpty } from 'lodash'

import Loader from '@components/BaseComponent/Loader.vue'
import icon from '@components/icons/icon.vue'
import { mapActions, mapState } from 'vuex'

import CalendarActionsWrapper from '@components/Calendar/CalendarActionsWrapper.vue'
import UiCalendar from '@components/Calendar/index.vue'
import CheckInOutModal from '@/src/router/views/attendance/staff-check-in-out/staffCheckInOutModal.vue'
import UiModalContainer from '@components/UiElements/UiModalContainer.vue'
import { formatDateString } from '@utils/format-date'
import timeMixin from '@src/mixins/timeMixin'
import WeeklyView from '../CheckInOutWeekly.vue'
import StaffCheckInOutFilter from '@src/router/views/attendance/staff-check-in-out/staffCheckInOutFilter.vue'
import GENERAL_CONSTANTS from '@src/constants/general-constants'

/* UTILS */
import {
  INITIAL_START_TIME,
  INITIAL_END_TIME,
} from '@/src/constants/attandance/attandance-constant'
import { getTimeRange } from '@utils/timeTableUtils'
import {
  getStudentAttendanceWeek,
  getSubjectEvent,
} from '@src/router/views/attendance/CheckInOutUtils.js'
import { convertUTCTimeIntoLocalTime } from '@/src/utils/generalUtil'
import NoRecordFound from '@/src/components/BaseComponent/NoRecordFound.vue'
import DayListViewCheckInOut from '@/src/router/views/attendance/campus-attendance/CampusAttendanceDailyView.vue'
import WeeklytDetailedModal from '@components/UiElements/UiModalContainer.vue'
export default {
  name: 'TeacherAttendance',
  components: {
    CheckInOutModal,
    StaffCheckInOutFilter,
    CalendarActionsWrapper,
    UiCalendar,
    UiModalContainer,
    icon,
    Loader,
    WeeklyView,
    DayListViewCheckInOut,
    WeeklytDetailedModal,
    NoRecordFound,
  },
  mixins: [timeMixin],
  page: {
    title: 'Staff Attendance',
    meta: [
      {
        name: 'description',
        content: 'Biometric Staff Attendance ',
      },
    ],
  },
  data() {
    return {
      isEmpty,
      isLoading: false,
      forceRenderCalendar: 1,
      filterView: 'Day',
      attendanceTranslation: 'attendanceTranslation',
      currentDate: new Date(),
      dateTimeRange: null,

      dayRange: ['monday', 'friday'],
      timeRange: ['0:00 am', '0:00 am'],
      tempDate: {},
      routinesDetailModal: false,

      attendance: [],
      respDataObjDump: [],
      events: [],
      teacherList: [],
      dayModal: false,
      detailsEventsArr: [],
      student_id: null,
      INITIAL_START_TIME,
      INITIAL_END_TIME,
    }
  },
  computed: {
    ...mapState({
      classId: (state) => state.layout.currentSectionScope?.id,
      checkInModal: (state) => state.layout.checkInModal,
      checkOutModal: (state) => state.layout.checkOutModal,
      selectedClass: (state) => state.layout.currentSectionScope.id,
    }),
  },
  watch: {
    classId: {
      deep: true,
      immediate: true,
      handler() {
        if (this.classId) {
          this.loadteacherList()
          this.applyDateFilter()
        }
      },
    },
  },
  created() {
    const stat = {}
    this.rightBarStats(stat)
  },
  methods: {
    loadteacherList() {
      let data = {
        role_id: 'section_teacher',
      }
      const getTeacherCampusBase = { ...data, getTeacherCampusBase: true }
      this.getTeacherList(getTeacherCampusBase).then((resp) => {
        this.teacherList = resp.data.records.map((teacher) => ({
          id: teacher.id,
          full_name: `${teacher.first_name} ${teacher.last_name}`,
          image: teacher?.image,
        }))
      })
    },
    rightBarStats(stat) {
      const rightBarContent = {
        header: {
          title: 'Attendance',
          buttons: [
            {
              title: 'RB_HEADER_BUTTON1_TITLE',
              classes: ['primary-button-right'],
              action: { name: 'layout/setCheckInModal', payload: {} },
            },
            {
              title: 'RB_HEADER_BUTTON2_TITLE',
              classes: ['primary-button-right'],
              action: { name: 'layout/setCheckOutModal' },
            },
          ],
        },
        stats: [
          {
            title: GENERAL_CONSTANTS.STATS_AND_SUMMARY,
            categories: [
              {
                text: 'TOTAL_TEACHER',
                value: `${
                  stat?.total_checked_in ? stat?.total_checked_in : GENERAL_CONSTANTS.EMPTY_RECORD
                }`,
              },
              {
                text: 'TEACHER_ATTENDANCE_IN',
                value: `${
                  stat?.total_checked_out ? stat?.total_checked_out : GENERAL_CONSTANTS.EMPTY_RECORD
                }`,
              },
              {
                text: 'TEACHER_ATTENDANCE_OUT',
                value: `${stat?.total_users ? stat?.total_users : GENERAL_CONSTANTS.EMPTY_RECORD}`,
              },
            ],
          },
        ],
      }
      this.setRightbarContent(rightBarContent)
    },
    setCalendarDate(dt) {
      this.currentDate = dt
    },
    clickDay(date) {
      this.detailsEventsArr = this.attendance.filter((single) => single.date === date)
      this.dayModal = true
    },
    singleRecord(record) {
      this.detailsEventsArr = [record]
      this.dayModal = true
    },
    handleClick(status) {
      switch (status) {
        case 'close':
          this.dayModal = false
          break
        case 'cancel':
          this.dayModal = false
          break
      }
    },

    setCalendarDateRange(dtr) {
      this.tempDate.dateTimeRange = this.dateTimeRange
      this.dateTimeRange = dtr

      // function use For set Range for Weekly call
      this.initializeFilterForWeek(this.dateTimeRange.startDate, this.dateTimeRange.endDate)

      this.forceRenderCalendar++
    },

    initializeFilterForWeek(startingDate, endingDate = null) {
      const startDate = new Date(startingDate)
      const endDate = new Date(startingDate)

      // Setting this to only get range for 7 days apart
      endDate.setDate(endDate.getDate() + 6)

      const startDayOfWeek = formatDateString(startDate, 'EEEE').toLowerCase()
      const endDayOfWeek = formatDateString(endDate, 'EEEE').toLowerCase()

      this.dateTimeRange = {
        startDate: startDate,
        endDate: endDate,
      }

      this.tempDate.dayRange = this.dayRange

      this.dayRange.splice(0, 1, startDayOfWeek)
      this.dayRange.splice(1, 1, endDayOfWeek)
    },

    setFilter(filterView) {
      this.attendance = []
      this.filterView = filterView

      if (filterView === 'Week') {
        this.initializeFilterForWeek(this.currentDate)
      }

      this.applyDateFilter()
      this.forceRenderCalendar++
    },
    getDay(date) {
      return new Date(date).getDate()
    },

    getTimeOnly(time) {
      const timeObj = time.split(' ')

      return timeObj[0]
    },
    resetFilter() {
      this.teacher_id = null
      this.applyDateFilter()
    },
    applyDateFilter() {
      const data = {
        section_id: this.selectedClass,
        skipSectionHeader: true,
      }
      if (this.teacher_id) {
        data.user_id = this.teacher_id
      }
      data.user_type = 'section_teacher'
      switch (this.filterView) {
        case 'Month':
          data.month_year = formatDateString(this.currentDate)
          this.loadMonthsRecord(data)
          break
        case 'Week':
          data.from_date = formatDateString(this.dateTimeRange.startDate)
          data.to_date = formatDateString(this.dateTimeRange.endDate)

          this.loadWeekRecord(data)
          break
        case 'Day':
          data.date = formatDateString(this.currentDate)
          this.loadDayRecord(data)
      }
    },
    transformRecordsForMonth(resp) {
      const records = []
      Object.keys(resp).forEach((date) => {
        resp[date].forEach((att, idx) => {
          const newRecord = {
            id: att.bulk_check_id,
            date: att.checked_at.split('T')[0],
            checked_count: att.check_count,
            last_name: att.checked_by_lname,
            first_name: att.checked_by_fname,
            checked_type: att.checked_type,
            checked_time: convertUTCTimeIntoLocalTime(att.checked_at.split('T')[1], 'hh:mm A'),
            checkedStatus: {
              color: att.checked_type === 'checked_in' ? '#267C26' : '#921A1D',
              presentColor: '#267C26',
            },
            time: formatDateString(att.checked_at, 'hh:mm a'),
          }

          if (idx < 3) {
            newRecord.isAction = false
          } else if (idx === 3) {
            newRecord.isAction = true
          }
          records.push(newRecord)
        })
      })
      this.attendance = records
    },
    transformRecordsForDay(resp) {
      const records = []
      Object.keys(resp).forEach((student) => {
        // records.push(resp[student])
        const checkIn = []
        const checkOut = []
        resp[student].forEach((att, idx) => {
          if (att.checked_type === 'checked_in') {
            checkIn.push({
              check: convertUTCTimeIntoLocalTime(att.checked_at.split('T')[1], 'hh:mm A'),
              comment: att.comment,
            })
          }
          if (att.checked_type === 'checked_out') {
            checkOut.push({
              check: convertUTCTimeIntoLocalTime(att.checked_at.split('T')[1], 'hh:mm A'),
              comment: att.comment,
            })
          }
        })
        resp[student][0].checked_list = { check_in: checkIn, check_out: checkOut }
        records.push(resp[student][0])
      })
      return records
    },
    loadMonthsRecord(data = {}) {
      this.isLoading = true
      this.loadTeacherCheckInAndOut(data)
        .then((resp) => {
          this.transformRecordsForMonth(resp.records)
          this.rightBarStats(resp.meta)
        })
        .finally(() => {
          this.isLoading = false
        })
    },
    loadDayRecord(data = {}) {
      this.isLoading = true
      this.loadTeacherCheckInAndOut(data)
        .then((resp) => {
          this.attendance = this.transformRecordsForDay(resp.records)

          this.rightBarStats(resp.meta)
        })
        .finally(() => {
          this.isLoading = false
        })
    },

    loadWeekRecord(data = {}) {
      // Weekly call function
      let attendances = null
      let timeRange = null
      this.isLoading = true
      this.loadTeacherCheckInAndOut(data)
        .then((resp) => {
          this.rightBarStats(resp.meta)

          this.respDataObjDump = resp.records
          // format the Data before sending to a weeklyView component
          attendances = getStudentAttendanceWeek(resp.records)

          // Here we send the For data to A function to set
          timeRange = getTimeRange(attendances)
          this.timeRange.splice(0, 1, timeRange[0])
          this.timeRange.splice(1, 1, timeRange[1])

          this.events = attendances
        })
        .finally(() => {
          this.isLoading = false
        })
    },

    handleRoutinesDetailClick(eventType) {
      if (eventType === 'close') {
        this.routinesDetailModal = false
      }
    },

    viewEvent(eventId) {
      let detailsEventsArr = []
      const event = this.events.find((event) => event.id === eventId)
      if (event) {
        detailsEventsArr.push(event)

        if (event?.isOverlapped) {
          const { overlappedIds } = event || {}
          let allOverlappedEvents = null
          const attendances = []
          // format Data
          Object.keys(this.respDataObjDump).forEach((date) => {
            this.respDataObjDump[date].forEach((record) => {
              let date = record.date + 'T' + record.checked_at
              date = new Date(date)
              let endTime = date.setMinutes(date.getMinutes() + 30)
              endTime = new Date(endTime)
              const endTimeFormat =
                endTime.getHours().toString().padStart(2, '0') +
                ':' +
                endTime.getMinutes().toString().padStart(2, '0') +
                ':' +
                endTime.getSeconds().toString().padStart(2, '0')
              const event = getSubjectEvent(record, endTimeFormat)
              attendances.push(event)
            })
          })

          // Find overlap events array and pass to modal
          allOverlappedEvents = overlappedIds.map((id) => {
            return attendances.find((obj) => obj.id === id)
          })

          detailsEventsArr = [...detailsEventsArr, ...allOverlappedEvents]
        }
        this.detailsEventsArr = detailsEventsArr
        this.routinesDetailModal = true
      }
    },

    closeStudentCheckModal() {
      if (this.checkInModal) {
        this.$store.dispatch('layout/setCheckInModal')
      } else if (this.checkOutModal) {
        this.$store.dispatch('layout/setCheckOutModal')
      }
    },
    ...mapActions('teacher', ['loadTeacherCheckInAndOut', 'getTeacherList']),

    ...mapActions('layout', ['setRightbarContent', 'setBulkCheckInModal', 'setBulkCheckOutModal']),
  },
}
</script>

<style lang="scss" module>
.widthItems {
  min-width: 31%;
}
.present {
  color: #267c26;
  background-color: #e9f2e9;
}
.delay {
  color: #ffc201;
  background-color: #fff9e6;
}
.absent {
  color: #ea3535;
  background-color: #fce1e1;
}
.halfday {
  color: #ff9421;
  background-color: #fff6ed;
}
.holiday {
  color: #357adb;
  background-color: #eaf1ff;
}
</style>

<style lang="scss" scoped>
@media screen and (max-width: 450px) {
  .text_size {
    font-size: 11px;
  }
  .dot_size {
    width: 4px;
    height: 4px;
    padding-left: 4px;
  }

  .viewMoreText {
    padding-top: 8px;
    padding-left: 7px;
    font-size: 8px;
  }
}
</style>
